
.light {
  --clr-bg: #fcfcfc;
  --clr-bg-alt: #fff;
  --clr-fg: #555;
  --clr-fg-alt: #444;
  --clr-primary: #2978b5;
  --shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.dark {
  --clr-bg: #23283e;
  --clr-bg-alt: #2a2f4c;
  --clr-fg: #bdbddd;
  --clr-fg-alt: #cdcdff;
  --clr-primary: #90a0d9;
  --shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000a1f8f;
  z-index: 9999;
}

.ReactModal__Content {
  padding: 0 !important;
  border: unset !important;
  background-color: #000A1F !important;

}
.ReactModal__Body--open{
  overflow: hidden !important; 
}
.container_modal{
  padding: 0 20px 20px 20px;
  text-align: justify;
}
.thumb.selected{
  border:3px solid white !important
}
.thumb{
  border:3px solid #333 !important;
}
.modal__img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.modal_title{
  color: var(--clr-fg-alt);
  font-size: 34px;
}
.modal_desc_item-icon{

  display: flex;
  justify-content: center;
  gap: 10px;
}
.thumb.selected:hover{
  cursor: pointer;
  /* border: 3px solid white !important; */
}
.modal_desc_link .MuiSvgIcon-root{
  color: var(--clr-fg);
}

.modal_desc_item-title{
  font-size: 12px;
  margin-bottom: 4px;
  /* background-color: red; */
  color: var(--clr-fg-alt);
}
.modal_desc_item-text{
  font-size: 24px;
  font-weight: 600;
  color: var(--clr-fg-alt);

}
.modal_project_desc{
  color: var(--clr-fg-alt);
  font-size: 14px;
  padding:0 20px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.carousel .control-dots{
  background: unset !important;
}
.scrollbar
{
  width: 100%;
  min-height: fit-content;
  background-color: var(--clr-bg);
	overflow-y: auto;
	margin-bottom: 25px;
}
.btn-text-white{
  color: #bdbddd
}
.btn--plain{
  text-transform: initial;
  background-color: var(--clr-bg-alt);
  box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
  border: 0;
  color: var(--clr-fg-alt);

}
.modal_footer{
  display: flex;
  justify-content: center;
}
.modal_close{
  margin: 20px 0 60px 0 !important;
    z-index: 999;
    padding:  10px 30px !important;
    color:white;
    border: 1px solid #EF4444 !important;
    background-color: #EF4444 !important;
    /* border-radius: 100px; */
    /* -webkit-border-radius: 100px; */
    /* -moz-border-radius: 100px; */
    /* -ms-border-radius: 100px; */
    /* -o-border-radius: 100px; */
}
.modal_close:hover{
  cursor: pointer;
  background-color: #f33b3b !important;
}
.modal_desc_link{
  color: white;
  text-decoration: underline;
}
.modal_img{
  width: 100%;
  height: 100%;
  max-height: 100vh;
  object-fit: contain;
}

.modal_skeleton {
  position: relative;
  /* height: 13px;
  margin: 10px 0; */
  border-radius: 2px;
  background: #ffffff;
  overflow: hidden;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}
.modal_desc{
  background-color: var(--clr-bg);
  justify-content: space-between;
  padding: 20px 40px;
  color: var(--clr-fg-alt);
  margin: 20px 0px 20px 0px;
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateX(-100px);
  transition: all 500ms ease-in-out;
  -webkit-transform: translateX(-100px);
  -moz-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  -o-transform: translateX(-100px);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}


.ReactModal__Content{
  width: 84% !important;
}
.modal_title{
  text-align: center;
}
.modal_desc_item{
  border-bottom: 1px solid var(--clr-fg);
}

@media screen and (min-width: 1200px) {
  .ReactModal__Content{
    width: 40% !important;
  }
  .modal_desc{
    display: flex;
  }
  .modal_desc_item{
    border-bottom: unset;
  }
  .modal_desc_item:not(:last-child){
    border-right: 1px solid var(--clr-fg);
  }
  .modal_desc_item{
    padding: 15px 10px;
    width: 33%;
    text-align: center;
  }  

}
.modal_desc_item{
  padding: 15px 10px;
  text-align: center;
}  

.modal_project_desc ul{
  list-style: circle;
  margin-left: 30px;
  margin-top: 10px;
}

.modal_skeleton::before  {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(    to right,    #d9d9d9 0%,    #d3d5db 20%,    #d9d9d9 40%,    #d9d9d9 100%  );
  background-size: 450px 400px;
  background-repeat: no-repeat;
  animation: shimmer 1.4s linear infinite;
  -webkit-animation: shimmer 1.4s linear infinite;
}
@keyframes shimmer {
  0% {
    background-position: -450px 0px;
  }
  100% {
    background-position: 450px 0px;
  }
}


.force-overflow
{
	min-height: 450px;
}

#style-3::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

#style-3::-webkit-scrollbar-thumb
{
	background-color: #23283e;
}
