* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  background-color: inherit;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  box-shadow: none;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
.app{
  max-width: 100%;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
.titleH2 {
  line-height: 1.2;
  color: var(--clr-fg-alt);
}
.titleH2::placeholder{
  font-weight: bold;
  color: white;
}
h1 {
  font-size: 4rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.3rem;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

@media (max-width: 900px) {
  h1 {
    font-size: 2.6rem;
  }
}

