.contact {
  flex-direction: column;
}
@media screen and (min-width: 1200px) {
  .contact_container{
    display: flex;
    gap: 10px;
  }
  
}
.contact_item{
  display: flex !important;
  gap: 5px;
  margin-bottom: 10px;
}
.contact_item p{
  align-self: center;
}

.contact_item:hover svg{
  color: white;
}
.contact_item:hover p{
  color: white;
}
.contact_item{
  background-color: transparent;
}