.project {
  padding-bottom: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
  position: relative;
  width: 100%;
  border: 1px solid #009AC5;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.project:hover {
  transform: translateY(-7px);
}

.project__detail{
  /* background: yellow; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 20px;
}
/* .  */
.project__detail-button{
  display: none;
}
.project:hover .project__detail-button {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  background-color: unset;
  backdrop-filter: blur(4px) ;
  width: 90%;
  right: 5%;
  top: 0%;
  height: 90%;
}
.button__detail{
  border: 2px double white !important;
}
.project__description {
  margin-top: 1em;
}

.project__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.project__stack-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.project .link--icon {
  margin-left: 0.5em;
}
.project-detail {
  padding-right: 2em;
  padding-left: 2em;
  position: relative;
}
.section__container{
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  overflow: hidden;
}
.section__container h2{
  width: fit-content;
  text-align: center;
  position: relative;
}
.section__container hr{
  width: 110%;
  position: absolute;
  bottom: -15%;
  left:-5%;
  /* margin-left:50px; */
  margin-top: 10px;
  border-top: 5px solid #00C7FF;
}

.text-primary{
  color: #00C7FF;
}